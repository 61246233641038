@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  background-color: #d9dbdb;
}
body {
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

p,
a,
div,
span {
  font-family: "Poppins", sans-serif;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  gap: 24px;
  padding-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-group a {
  text-decoration: none;
  display: inline-flex;
  gap: 20px;
  font-size: 20px;
  line-height: 1.2;
  color: #222e48;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: inherit;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 16px 32px;
  border-radius: 0px;
  transition: all 0.7s ease-in-out;
  color: #fff;
  border: 1px solid #004a63;
  background-color: #004a63 !important;
}

.Navbar .logo img {
  /* filter: drop-shadow(0 0 0.75rem rgb(187, 183, 159)); */
}
.Navbar {
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 111;
}

.Navbar-c {
  padding: 0 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 50%;
}
.logo img {
  /* width: 65%; */
  width: 60%;
}
.f-logo img {
  width: 100%;
}
.Footer1 div {
  width: 30%;
}
.btn-group a {
  background-image: linear-gradient(white, white),
    linear-gradient(#004a63, #004a63);
  background-size: 0 100%, auto;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.5s ease-out;
}
.btn-group a:hover {
  background-color: white;
  color: #004a63;
  transition: 0.3s ease;
  background-size: 100% 100%, auto;
}

/* src/styles/animations.css */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-from-left {
  opacity: 0;
}

.animate-from-right {
  opacity: 0;
}

.animate {
  opacity: 1 !important;
}

.animate-from-left.animate {
  animation: slideInFromLeft 1s forwards;
}

.animate-from-right.animate {
  animation: slideInFromRight 1s forwards;
}
.animate-from-top.animate {
  animation: slideInFromTop 1s forwards;
}

.animate-from-bottom.animate {
  animation: slideInFromBottom 1s forwards;
}
.MobileNavbar {
  display: none;
}
.Footer {
  padding: 5px 8%;
  /* background-color: #d5d9d8; */
  padding-top: 25px;
  background-color: white;
  margin-top: 35px;
}

.Footer1 {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.Footer1 img {
  margin-top: 40px;
}
.Footer a {
  text-decoration: none;
  color: #404a60;
  font-size: 16px;
}
.Footer li {
  font-size: 18px;
}
.Footer span {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 30px;
  font-size: 18px;
}
.Footer ul {
  padding: 0;
  margin: 0;
}
.Footer ul li {
  list-style-type: none;
  cursor: pointer;
}

.svg {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.HeroSection {
  position: relative;
  height: 100vh; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white; /* Adjust text color as needed */
  padding-top: 100px;
}
.aboutContent h2 {
  color: #004a63;

  font-size: 32px;
}
.HowWorks h2 {
  color: #004a63;
  text-align: center;
  font-size: 32px;
  margin-top: 0;
  margin-bottom: 30px;
}
.HowWorks h4 {
  color: #004a63;
  /* margin: 0; */
  font-size: 24px;
}
.Services h2 {
  color: #fbfbfc;
  text-align: center;
  font-size: 32px;
  margin: 0;
  margin-bottom: 12px;
}
.Services span {
  text-align: center;
  display: block;
  color: white;
}
.Services .p {
  text-align: center;
  font-size: 20px;
  color: white;
  margin-bottom: 30px;
}
.Services h4 {
  color: #004a63 !important;
  font-size: 24px;
}
.icon-count-2-outer .icon-count-2 span.icon-count-number {
  color: #004a63 !important;
}
.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.backgroundImage img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the image covers the whole section */
}

.HeroContent {
  position: relative;
  z-index: 1;
  margin: 0 auto;
}
.Services {
  background-color: hsl(180deg 65.71% 11.02%);
  padding: 4% 8%;
}
.SerCards {
  display: flex;
}
.SerCard {
  display: flex;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.icon-count-2-outer {
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  overflow: hidden;
  padding: 5px 0px;
}
.icon-count-2-outer .icon-count-2 {
  padding: 100px 30px 30px 30px;
  margin: 0px 5px;
  height: 100%;
}
.icon-count-2-outer .icon-count-2 span.icon-count-number {
  position: absolute;
  right: 30px;
  top: 20px;
  font-size: 80px;
  font-weight: 600;
  color: rgba(177, 177, 177, 0.2);
  line-height: 120px;
  font-family: "Poppins", sans-serif;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.icon-count-2-outer .icon-count-2 span.icon-count-number:after {
  content: "";
  width: 15px;
  height: 2px;
  background-color: #000;
  left: -50%;
  top: 50%;
  position: absolute;
  margin-top: -2px;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.icon-xl.inline-icon img {
  width: 80px;
}
.icon-content h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: #000;
  margin-bottom: 15px;
}
.icon-content p {
  line-height: 24px;
  margin-bottom: 20px;
}
.bg-white {
  background-color: white;
}
.HowWorks {
  padding: 4% 8%;
  margin-top: 5%;
}
.lp .HowWorks{
  padding: 0% 8%;
  margin-top: 0%;
}
.workCards {
}
.preworkCard {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 32.333333%;
}
.workCard img {
  width: 100%;
}
.workCards {
  display: flex;
  justify-content: space-between;
}

.workCard {
  position: relative;
  height: 320px;
}

.workCard img {
  width: 100%;
  height: 100%;
  display: block; /* Ensures the image takes its natural height */
}
.HowWorks {
  position: relative;
}
.scale-in-center span {
  text-align: left !important;
}
.workCards {
  position: relative;
  width: 100%;
}

.workCard {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Will inherit the height of the parent container, which is determined by the image height */
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Adjust the opacity and color as needed */
  z-index: 1;
}

.above_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 2;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  font-size: 20px;
  line-height: 30px;
}

.figcaption-number span {
  font-size: 42px;
  line-height: 42px;
  height: 42px;
  font-weight: 600;
  margin-top: 10px;
  display: block;
  color: #004a63;
}
span {
  color: #038b99;
  font-weight: bold;
}
.figcaption h4 {
  text-align: center;
  margin-bottom: 20px;
}
.figcaption-number {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.figcaption-number .span {
  width: 2px;
  background-color: black;
  height: 30px;
}
.AboutUs {
  background-color: #d9dbdb;
  padding: 8% 8%;
  margin-top: 4%;
}
.aboutContainer {
  display: flex;
  align-items: center;
}

.aboutContainer .aboutContent {
  width: 40%;
  border: 15px solid #004a63;
  padding: 20px;
}
.aboutContainer .btn-group {
  justify-content: left;
}
.aboutImages {
  width: 60%;
  text-align: right;
}
.aboutImages img {
  width: 60%;
}
.gallery-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 1rem;
  width: 60%;
}

.button-prev,
.button-next {
  position: absolute;
  font-family: "Material Icons";
  font-size: 1.5rem;
  cursor: pointer;
  user-select: none;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: 800;
  color: black;
}

.button-prev {
  left: 12%;
  z-index: 3;
  background-color: white;
}

.button-next {
  right: 12%;
  z-index: 3;
  background-color: white;
}

.button-prev:hover,
.button-next:hover {
  color: rgb(39, 38, 38);
}

.gallery-track {
  aspect-ratio: 168/100;
  position: relative;
  width: 90%;
  overflow: hidden;
  height: 500px;
}

.slide-image {
  aspect-ratio: 168/100;
  position: absolute;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  transition: width 4.5s ease-in-out, height 4.5s ease-in-out !important;
  will-change: width, height !important; /* Optimize performance */
}

.slide-image:hover {
  width: 150% !important; /* Increase width */
  height: 150% !important; /* Increase height */
}
.gallery-footer {
  position: absolute;
  bottom: 5%;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

/* .bubble-outer {
  cursor: pointer;
  border : 3px solid black;
  border-radius: 8px;
} */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

iframe {
  aspect-ratio: 16/9;
}

.video-container {
  margin: 2rem;
  max-height: 300px;
}

main {
  display: grid;
  place-items: center;
  padding-top: 90px;
}

.container {
  display: grid;
  place-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  --position: 50%;
  height: 70vh;
}

.image-container {
  /* width: 100%; */
  /* height: 90vh; */
  aspect-ratio: 1/1;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.image-before {
  position: absolute;
  inset: 0;
  width: var(--position);
  filter: grayscale(100%);
}

.slider {
  position: absolute;
  inset: 0;
  cursor: pointer;
  opacity: 0;
  /* for Firefox */
  width: 100%;
  height: 100%;
}

.slider:focus-visible ~ .slider-button {
  outline: 5px solid black;
  outline-offset: 3px;
}

.slider-line {
  position: absolute;
  inset: 0;
  width: 0.2rem;
  height: 100%;
  background-color: #fff;
  /* z-index: 10; */
  left: var(--position);
  transform: translateX(-50%);
  pointer-events: none;
}

.slider-button {
  position: absolute;
  background-color: #fff;
  color: black;
  padding: 0.5rem;
  border-radius: 100vw;
  display: grid;
  place-items: center;
  top: 50%;
  left: var(--position);
  transform: translate(-50%, -50%);
  pointer-events: none;
  /* z-index: 100; */
  box-shadow: 1px 1px 1px hsl(0, 50%, 2%, 0.5);
}
.bubble-inner {
  border: 5px solid transparent;
  border-radius: 4px;
}
.aboutImages {
  position: relative;
}
.gallery-container {
  position: relative;
  z-index: 2;
}
.abs-gallery {
  position: absolute;
  top: 10%;
  right: 0%;
  z-index: 1;
  height: 100%;
}
.HeroContent h2 {
  font-size: 50px;
  line-height: 70px;
  margin: 0;
  color: hsl(0deg 0% 100%);
}
p br{
  display: none;
}
.HeroContent p {
  font-size: 20px;
  color: white;
  font-weight: 900;
  margin: 30px;
}
.nb{
  background-color: #004a63;
  padding: 10% 8%!important;
  padding-bottom: 3%!important;
  color: white;

}
.nb h1{
  margin-bottom: 15px;
  font-size: 32px;
}
.elementor-element-populated{
  padding: 0% 8%;
 
}
.elementor-element-populated h2{
  color: #004a63;
  margin: 10px 0px;
}
.elementor-element-populated h4{
  color: #004a63;
  margin: 10px 0px;
}
.elementor-element-populated li{
  list-style: none;
}
.elementor-element-populated li strong{
  color: #004a63;
  
}
.elementor-element-populated  strong{
  color: #004a63;
  font-weight: normal;
}
.elementor-element-populated em strong{
  color: #004a63;

}
.elementor-element-populated span{
  color: black;
  font-weight: normal;
}
.sec .elementor-element-populated{
  padding: 0%;
}

.elementor-element-populated a{
  color: #038b99;
  text-decoration: none;
}
.WhyChooseUs {
  background-image: url(./Component/assets/images/ct.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  border: 1px solid #004a63;
  border-radius: 0px;
  margin: 30px auto 0;
  position: relative;
  width: 90%;
  padding: 12px;
  margin-bottom: 30px;
}
.WhyChooseUs:before {
  background: #e7e6e6cc;
  content: "";
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.ct-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
}
.ct-flex div {
  width: 33.33%;
}
.ct-flex div .btn-group {
  width: 100%;
}
.ct-flex div img {
  width: 85%;
  border-radius: 12px;
}
.ct-flex h2 {
  text-align: left;
  font-size: 28px;
  margin: 0;
  margin-bottom: 12px;
  color: #004a63;
}
.preworkCard {
  cursor: pointer;
}
.preworkCard:hover .figcaption-number span {
  color: #5f7a83;
  transition: 0.3s ease;
}
.preworkCard:hover .figcaption h4 {
  color: #5f7a83;
  transition: 0.3s ease;
}
.preworkCard:hover .above_content {
  color: rgb(199, 197, 197);
}
.SerCard {
  cursor: pointer;
}
.SerCard:hover .bg-white{
  background-color: hsl(180deg 65.71% 11.02%);
  border: 1px solid white;
  transition: 0.5s ease;
}
.SerCard:hover .icon-count-2-outer .icon-count-2 span.icon-count-number{
  color: white!important;
}
.SerCard:hover .icon-content h4{
  color: white!important;
}
.SerCard:hover .icon-content p{
  color: white;
}
.SerCard:hover .icon-xl.inline-icon img {
  width: 80px;
  filter: brightness(0) invert(1);
}
.SerCard:hover span.icon-count-number:after{
 background-color: white;
}
@keyframes slide-right-in {
  from {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slide-left-in {
  from {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slide-right-out {
  from {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes slide-left-out {
  from {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.rightIn {
  -webkit-animation: slide-right-in 0.5s 1 forwards;
  -moz-animation: slide-right-in 0.5s 1 forwards;
  -ms-animation: slide-right-in 0.5s 1 forwards;
  -o-animation: slide-right-in 0.5s 1 forwards;
  animation: slide-right-in 0.5s 1 forwards;
}

.leftIn {
  -webkit-animation: slide-left-in 0.5s 1 forwards;
  -moz-animation: slide-left-in 0.5s 1 forwards;
  -ms-animation: slide-left-in 0.5s 1 forwards;
  -o-animation: slide-left-in 0.5s 1 forwards;
  animation: slide-left-in 0.5s 1 forwards;
}

.rightOut {
  -webkit-animation: slide-right-out 0.5s 1 forwards;
  -moz-animation: slide-right-out 0.5s 1 forwards;
  -ms-animation: slide-right-out 0.5s 1 forwards;
  -o-animation: slide-right-out 0.5s 1 forwards;
  animation: slide-right-out 0.5s 1 forwards;
}

.leftOut {
  -webkit-animation: slide-left-out 0.5s 1 forwards;
  -moz-animation: slide-left-out 0.5s 1 forwards;
  -ms-animation: slide-left-out 0.5s 1 forwards;
  -o-animation: slide-left-out 0.5s 1 forwards;
  animation: slide-left-out 0.5s 1 forwards;
}
.cttt {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  text-align: center;
  width: 100%;
}
main {
  /* display: "grid",
    placeItems: "center",
    position: "relative",
    height: "100vh", */
  display: grid;
  place-items: center;
  position: relative;
  height: 70vh;
}
.nb strong{
  color: white;
  margin-right: 2px;
}
.nb a{
  color: white;
}
.container::before {
  content: ""; /* Necessary to make the pseudo-element display */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsl(201.46deg 34.2% 39.42% / 65%);
  z-index: 2; /* Ensure it's above the background image but below the content */
}
.btnnnn {
  position: absolute;
  bottom: -35%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  text-align: center;
  color: white;
  width: 100%;
}
.lp .btnnnn {
  bottom: -30%!important;
}

@media (max-width: 768px) {
  .Navbar {
    display: none;
  }

  .Footer1 {
    flex-direction: column;
    padding-bottom: 10px;
  }
  Footer {
    padding: 40px 7% !important;
    background-color: #d5d9d8;
  }
  .mobile {
    display: block;
  }

  .Footer span {
    padding-bottom: 90px;
    font-size: 15px;
  }
  .bottomFixed {
    background-color: hsl(180deg 65.71% 11.02%);
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1111;
    padding: 10px 0px;
  }
  .topFixed {
    text-align: center;
    padding: 10px;
  }
  /* Root variables for easy customization */
  :root {
    --btn-border: 1px solid rgba(255, 255, 255, 0.2);
    --btn-bg: #004a63; /* Background color for the button */
    --btn-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 1);
    --btn-text-color: #f4f4f4;
    --shine-color: #79aabe; /* Prominent shine */
    --shine-degree: -45deg;
    --shine-duration: 5s; /* Duration of the shine animation */
  }

  /* Styling for the button */
  .bottomFixed a {
    text-decoration: none;
    color: var(--btn-text-color);
    font-size: 24px;
    font-weight: 600;
    background-color: var(--btn-bg);
    width: 95%;
    display: block;
    margin: 0 auto;
    height: 90%;
    padding: 15px 0;
    border-radius: 10px;
    position: relative; /* Necessary for ::before positioning */
    overflow: hidden; /* Hide the overflow for the shine effect */
    border: var(--btn-border);
  }

  /* Shining animation */
  .bottomFixed a::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%; /* Start off screen */
    width: 100%;
    height: 100%;
    background: linear-gradient(
      var(--shine-degree),
      transparent 40%,
      /* Start the transparent part */ var(--shine-color) 50%,
      /* Narrower white shine */ transparent 75% /* End the transparent part */
    );
    animation: shine var(--shine-duration) linear infinite;
  }

  /* Keyframes for the shine animation */
  @keyframes shine {
    0% {
      left: -150%; /* Start off screen */
    }
    50% {
      left: 150%; /* Move across the button */
    }
    50.01% {
      left: -150%; /* Restart immediately */
    }
    100% {
      left: 150%; /* Move across the button */
    }
  }

  .MobileNavbar {
    display: block;
  }

  .btn-group a {
    padding: 12px 0px;
    font-size: 20px;
  }

  .btn-group {
    padding: 30px 0px !important;
    width: 100%;
  }
  .btn-group a {
    width: 100%;
  }
  .topFixed img {
    width: 100%;
  }
  .HeroContent h2 {
    font-size: 30px;
    line-height: 38px;
    margin: 0;
    color: white;
  }
  .HeroSection {
    height: auto;
    padding: 8%;
  }
  .aboutImages img {
    display: none;
  }
  .aboutContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .aboutContainer .aboutContent {
    width: 100%;
    padding: 20px 10px;
  }
  .aboutImages {
    width: 100%;
  }
  .gallery-container {
    width: 100%;
    padding: 0;
    margin-top: 30px;
  }
  .gallery-track {
    width: 65%;
    height: 370px;
  }
  .workCards {
    flex-direction: column;
  }
  .preworkCard {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }
  .SerCards {
    flex-direction: column;
  }
  .SerCard {
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .Footer1 div {
    width: 100%;
  }
  .aboutContent h2 {
    color: #004a63;
    font-size: 24px;
    margin-top: 0px;
  }
  .HowWorks h2 {
    color: #004a63;
    font-size: 24px;
    margin-top: 0px;
  }
  .HowWorks h4 {
    color: #004a63;
    /* margin: 0; */
    font-size: 20px;
  }
  .figcaption-number span {
    font-size: 32px;
    line-height: 42px;
    height: 42px;
    font-weight: 600;
    margin-top: 10px;
    display: block;
    color: #004a63;
  }
  .Services h2 {
    font-size: 24px;
  }
  .Services .p {
    text-align: center;
    font-size: 16px;
    color: white;
  }
  .icon-content h4 {
    font-size: 20px;
    margin-top: 8px;
  }
  .icon-count-2-outer .icon-count-2 span.icon-count-number {
    font-size: 45px;
  }
  .icon-count-2-outer .icon-count-2 {
    padding: 30px;
  }
  .icon-xl.inline-icon img {
    width: 60px;
  }
  .button-prev {
    left: 2%;
    z-index: 3;
    background-color: white;
  }

  .button-next {
    right: 2%;
    z-index: 3;
    background-color: white;
  }
  main {
    padding-top: 0px;
  }
  .container {
    place-content: baseline;
  }
  .cttt {
    top: 45%;
    width: 90%;
    /* margin: 0 auto; */
  }
  main {
    display: grid;
    place-items: center;
    position: relative;
    height: 50vh;
  }
  .btnnnn {
    top: 100%;
    display: none;
  }
  .container {
    height: 100%;
  }
  .AboutUs {
    padding: 2%;
    margin-top: 0;
  }
  .ct-flex{
    flex-direction: column;

  }
  .ct-flex div{
    width: 100%;
    text-align: center;
  }
  .ct-flex h2{
    text-align: center;
  }
  .slide-image{
    background-size: contain;
  }
 p br{
    display: block;
  }
}
